<template>
  <div class="mr-4">
    <v-subheader class="mb-2">Job Infomation</v-subheader>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="totalEstimateIds"
          label="Audiences Estimates"
          dense
          outlined
          disabled />
      </v-col>
      <v-col cols="8">
          <v-text-field
            label="Job Name"
            v-model="jobInfo.name"
            dense
            outlined
            required />
        <v-row>
          <v-col cols="6"
              class="mt-2">
            <v-menu
              v-model="jobDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  label="Date Start Job"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="jobDate = false" />
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-text-field
              class="pt-0"
              v-model="time"
              :rules="[rules.timeFormat]"
              label="Job Time"
              hint="sample format time 09:00"
              persistent-hint
              prepend-icon="mdi-clock-time-four-outline" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="mt-5"></v-divider>
  </div>
</template>

<script>
import * as moment from 'moment';

export default {
  name: 'AudienceJobInfo',
  props: {
    jobInfo: {
      type: Object
    }
  },
  data: (vm) => ({
    date: moment().format('YYYY-MM-DD'),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    jobDate: false,
    jobTime: '',
    time: null,
    ids: [],
    msisdn: [],
    totalEstimateIds: 0,
    rules: {
      timeFormat: value => {
          const pattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
          return (
            pattern.test(value) ||
            'Please, check time 24Hr. patten. Ex: "09:00", "13:00"'
          );
        },
    }
  }),

  computed: {
    computedDateFormatted() {
      this.jobInfo.dateJob = this.formatDate(this.date);
      return this.formatDate(this.date);
    },
  },

  created () {
    this.totalEstimateIds = this.jobInfo.totalEstimateIds.toLocaleString();
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },

    time(val) {
      const dayStart = new Date(`${this.date}T${val}`);

      this.jobInfo.startTime = Date.parse(dayStart.toLocaleString());
      this.jobInfo.endTime = this.jobInfo.startTime + (1000 * 10);
    },

    // ids(val) {
    //   const idsEstimate = val.split(',');
    //   this.jobInfo.ids = idsEstimate;
    // },

    // msisdn(val) {
    //   const msisdnEstimate = val.split(',');
    //   this.jobInfo.msisdn = msisdnEstimate;
    // }
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
  },
}
</script>